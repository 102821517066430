<template>
  <div class="auth-wrapper auth-v2 overlay text-black">
    <b-link
      class="brand-logo d-flex justify-content-center align-items-center"
      :to="{ name: 'Login' }"
    >
      <img :src="this.appLogoImage" width="70" height="70" />
      <h2
        class="brand-text text-primary m-0 ml-0"
        style="font-size: 2rem; font-weight: 600; white-space: nowrap;"
      >
        {{ this.appName }}
      </h2>
    </b-link>
    <b-row class="auth-inner bg">
      <b-col
        lg="6"
        class="my-auto"
        style="display: flex; align-items: center; justify-content: center"
      >
        <Guide />
      </b-col>

      <b-col lg="6" class="my-auto px-2 p-lg-5">
        <b-col sm="10" md="8" lg="9" class="px-xl-2">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to
            <span class="text-primary font-weight-bold">{{
              this.appName
            }}</span>
          </b-card-title>
          <!-- <b-card-text class="m-0"> Please sign-up </b-card-text> -->

          <validation-observer ref="registerFormValidation">
            <b-form class="auth-register-form mt-1" @submit.prevent>
              <b-form-group class="mb-1">
                <label for="accountType">Select Account Type</label>

                <!-- <b-form-radio
                  v-model="role"
                  name="accountType"
                  value="se"
                  style="width: 100%"
                >
                  <div class="d-flex justify-content-between">
                    <span>Serving Person </span>
                    <span
                      class="ml-2"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        font-size: 12px !important;
                        text-align: right !important;
                      "
                      > حاضر سروس شخص </span
                    >
                  </div>
                </b-form-radio> -->

                <b-form-radio
                  v-model="role"
                  name="accountType"
                  value="re"
                  style="width: 100%"
                >
                  <div class="d-flex justify-content-between">
                    <span>Retired Person </span>
                    <span
                      class="ml-2"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        font-size: 12px !important;
                        text-align: right !important;
                      "
                      >ریٹائرڈ شخص</span
                    >
                  </div>
                </b-form-radio>
                <b-form-radio
                  v-model="role"
                  name="accountType"
                  value="fs"
                  style="width: 100%"
                >
                  <div class="d-flex justify-content-between">
                    <span>Family of Shaheed/Deceased </span>
                    <span
                      class="ml-2"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        font-size: 12px !important;
                        text-align: right !important;
                      "
                      >شہید/مرحوم کے لواحقین</span
                    >
                  </div>
                </b-form-radio>
                <!-- <b-form-radio
                  v-model="role"
                  name="accountType"
                  value="st"
                  style="width: 100%"
                >
                  <div class="d-flex justify-content-between">
                    <span>Student </span>
                    <span
                      class="ml-2"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        font-size: 12px !important;
                        text-align: right !important;
                      "
                      >طالب علم</span
                    >
                  </div>
                </b-form-radio> -->
              </b-form-group>

              <!-- <b-form-group>
                  <label for="is_officer" class="mt-2"
                    >Rank Type</label
                  >

                  <b-form-radio
                    v-model="is_officer"
                    name="officer"
                    :value="true"
                    style="width: 100%"
                  >
                    <b-col class="d-flex justify-content-between">
                      <span>Officer</span>
                      <span
                        class="ml-2"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          font-size: 12px !important;
                          text-align: right !important;
                        "
                      >
                        افسر
                      </span>
                    </b-col>
                  </b-form-radio>

                  <b-form-radio
                    v-model="is_officer"
                    name="jco_or"
                    :value="false"
                    style="width: 100%"
                  >
                    <b-col class="d-flex justify-content-between">
                      <span>JCO/OR</span>
                      <span
                        class="ml-2"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          font-size: 12px !important;
                          text-align: right !important;
                        "
                      >
                        جے سی او/ او آر</span
                      >
                    </b-col>
                  </b-form-radio>
                </b-form-group> -->



              <b-form-group>
                <label for="name" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Name </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >نام
                    </span>
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="name"
                  :rules="{ required, regex: /^[A-Za-z\s]{1,50}$/ }"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    name="name"
                    maxlength="50"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group label="First Name" label-for="firstName">
                    <validation-provider
                      #default="{ errors }"
                      name="firstName"
                      rules="required|alpha"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="firstName"
                        :state="errors.length > 0 ? false : null"
                        name="firstName"
                        placeholder="First Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group> -->

              <!-- <b-form-group label="Last Name" label-for="lastName">
                    <validation-provider
                      #default="{ errors }"
                      name="lastName"
                      rules="required|alpha"
                    >
                      <b-form-input
                        id="lastName"
                        v-model="lastName"
                        :state="errors.length > 0 ? false : null"
                        name="lastName"
                        placeholder="Last Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group> -->

              <b-form-group>
                <label for="cnic" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>CNIC </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >شناختی کارڈ
                    </span>
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="CNIC"
                  rules="required|integer|length:13"
                >
                  <b-form-input
                    id="cnic"
                    v-model="cnic"
                    :state="errors.length > 0 ? false : null"
                    name="cnic"
                    placeholder="61101XXXXXXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <span v-if="role === 'st'">
                <b-form-group>
                  <label for="parent_name" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span>Parent Name </span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                        "
                        >والد/والدہ کا نام
                      </span>
                    </div>
                  </label>

                  <validation-provider
                    #default="{ errors }"
                    name="parent_name"
                    :rules="{ required, regex: /^[A-Za-z\s]{1,50}$/ }"
                  >
                    <b-form-input
                      id="name"
                      v-model="parent_name"
                      :state="errors.length > 0 ? false : null"
                      name="parent_name"
                      placeholder="Parent name"
                      maxlength="50"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </span>
              <span v-if="role === 'st'">
                <b-form-group>
                  <label for="cnic-parent" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span>CNIC of Father/Mother </span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                        "
                        >والد/والدہ کا شناختی کارڈ نمبر
                      </span>
                    </div>
                  </label>

                  <validation-provider
                    #default="{ errors }"
                    name="parent_cnic"
                    rules="integer|length:13"
                  >
                    <b-form-input
                      id="parent_cnic"
                      v-model="parent_cnic"
                      :state="errors.length > 0 ? false : null"
                      name="parent_cnic"
                      placeholder="61101XXXXXXXX"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </span>

              
                
              

              <!-- <b-form-group label="Email" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="email"
                        :state="errors.length > 0 ? false : null"
                        name="email"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group> -->

              <b-form-group>
                <label for="mobile" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Mobile </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >موبائل</span
                    >
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  rules="required|integer|length:11"
                >
                  <b-form-input
                    id="mobile"
                    v-model="mobile"
                    :state="errors.length > 0 ? false : null"
                    name="mobile"
                    placeholder="0310XXXXXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="password" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Password </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >پاس ورڈ
                    </span>
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="confirm-password" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Confirm Password </span
                      ><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >پاس ورڈ کی تصدیق کریں</span
                    >
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="c-password"
                      v-model="passwordCon"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordConFieldType"
                      name="password"
                      placeholder="Re-type Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordConToggleIcon"
                        @click="togglePasswordConVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <span v-if="role === 'fs'">
                <b-form-group>
                  <label for="cnic-shaheed" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span>CNIC of Shaheed </span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                        "
                        >شہید/مرحوم کا شناختی کارڈ نمبر
                      </span>
                    </div>
                  </label>

                  <validation-provider
                    #default="{ errors }"
                    name="cnicshaheed"
                    rules="integer|length:13"
                  >
                    <b-form-input
                      id="cnicshaheed"
                      v-model="cnicShaheed"
                      :state="errors.length > 0 ? false : null"
                      name="cnic"
                      placeholder="61101XXXXXXXX"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <!-- <label for="relation-shaheed">
                    <span>Your Relation with Shaheed/Deceased </span>
                    <span style="font-family: 'Noto Nastaliq Urdu', serif"
                      >شہید/مرحوم کے ساتھ آپ کا تعلق</span
                    >
                  </label> -->
                  <label for="relation-shaheed" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span>Your Relation with Shaheed/Deceased</span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                        "
                        >شہید/مرحوم کے ساتھ آپ کا تعلق
                      </span>
                    </div>
                  </label>

                  <validation-provider #default="{ errors }" name="Ward">
                    <v-select
                      v-model="selectedRelationToShaheed"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="relations"
                      class="vSelectStyle bg-white"
                      placeholder="Select relation with shaheed/deceased"
                    >
                    </v-select>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </span>

              <b-button
                type="submit"
                variant="primary"
                block
                @click="validateForm"
              >
                Sign Up
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Already have an account? </span>
            <b-link :to="{ name: 'Login' }">
              <span>&nbsp;Click here</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { $themeConfig } from "@themeConfig";
import { mapActions } from "vuex";
import util from "@/util.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Guide from "@/components/newHome/Guide.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    Guide,
  },
  mixins: [util],
  data() {
    return {
      show: false,
      appName: "",
      appLogoImage: "",
      parent_cnic: null,
      parent_name: null,
      is_officer: false,
      password: "",
      passwordCon: "",
      cnic: "",
      dateOfRetirement: "",
      mobile: "",
      role: "re",
      email: null,
      name: null,
      motherName: null,
      dateOfBirth: null,
      cnicShaheed: null,
      nameShaheed: null,
      relations: [
        "Father",
        "Mother",
        "Husband",
        "Wife",
        "Son",
        "Daughter",
        "Brother",
        "Sister",
      ],
      selectedRelationToShaheed: null,
      passwordFieldType: "password",
      passwordConFieldType: "password",
      required,
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  methods: {
    ...mapActions({ register: "appData/register" }),
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    disabledRangeForDOB: function (date) {
      var today = new Date();
      var newDate = new Date(date);
      return newDate > today;
    },
    togglePasswordConVisibility() {
      this.passwordConFieldType =
        this.passwordConFieldType === "password" ? "text" : "password";
    },
    async validateForm() {
      const success = await this.$refs.registerFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      this.show = true;
      try {
        if (this.role === "fs" && this.cnic === this.cnicShaheed) {
          this.$swal({
            title: "CNIC of shaheed and user are the same",
            icon: "error",
            timer: 3000,
          });
          this.cnicShaheed = null;
          this.show = false;
          return;
        }

        if (this.role === "st" && this.cnic === this.parent_cnic) {
          this.$swal({
            title: "CNIC of student and parent are the same",
            icon: "error",
            timer: 3000,
          });
          this.cnicShaheed = null;
          this.show = false;
          return;
        }

        const data = {
          cnic: this.cnic,
          mobile: this.mobile,
          role: this.role,
          password: this.password,
          name: this.name,
          // parent_cnic: this.parent_cnic,
          // parent_name: this.parent_name,
          is_officer: false,
          relation_with_shaheed: this.selectedRelationToShaheed,
          cnic_shaheed: this.cnicShaheed,
        };
        const res = await this.register(data);
        this.show = false;

        if (res.status === 201) {
          this.$swal({
            title:
              "Your Credentials have been sent for verification. You will Receive a Call From 051 111130786 for Data Verification, Please verify Your Particulars when you Receive Call from this Number. You will be intimated via SMS on approval of Registration.",
            icon: "success",
          });
          this.$router.push({ name: "Login" });
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordConToggleIcon() {
      return this.passwordConFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  watch: {
    role() {
      if (this.role === "re") {
        this.nameShaheed = null;
        this.cnicShaheed = null;
        this.selectedRelationToShaheed = null;
        // this.parent_cnic = null;
        // this.parent_name = null;
        this.is_officer = null;
      }
      if (this.role === "st") {
        this.nameShaheed = null;
        this.cnicShaheed = null;
        this.selectedRelationToShaheed = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.overlay {
  background: url("~@/assets/images/slider/login-bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

@media (max-width: 400px) {
  .bg {
    margin-top: 100px !important;
  }
}

.text-black {
  color: black !important;
}

label {
  font-size: 110%;
  font-weight: 400;
  color: #000;
}

::placeholder {
  color: black;
}
.auth-wrapper {
  height: calc(100% + 100px);
}
.auth-inner::-webkit-scrollbar{
  display: none;
}
.auth-inner{
  margin-top: 100px;
}

</style>
